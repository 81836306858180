import * as React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';
import { byRoute } from 'platform/decorators/routes';
import HelperComponent from 'platform/classes/helper-component';
import Carousel from './components/carousel';
import ContactUs from './components/contact-us';
import Settings from 'platform/services/settings';
import SuccessModal from 'components/success-modal';
import { Shared } from 'modules';
import AllProductsList, { PageTypeEnum, PlacementEnum } from "./components/all-products";
import {Helmet} from "react-helmet";
import {helmetJsonLdProp} from "react-schemaorg";
import {Person} from "schema-dts";
import './style.scss';
import Collection from './components/collection';
import BrandsSlide from './components/brands/brands';
import NewsSlide from '../news/newsSlider';
import MobileBonusCard from './components/mobileBonusCard';
import Storage from 'platform/services/storage';



interface IState {
    authModalOpen: boolean;
    orderSuccessModalOpen: boolean;
}

@byRoute(ROUTES.MN)
class Home extends HelperComponent<{}, IState> {

    public state: IState = {
        authModalOpen: false,
        orderSuccessModalOpen: false
    };

    public componentDidMount(): void {
        setTimeout(() => {window.scrollTo(0, 0)},100);
    }

    private toggleAuthModal = () => {
        const { authModalOpen } = this.state;
        this.safeSetState({ successModalOpen: false, authModalOpen: !authModalOpen });
    };



    private closeOrderSuccessModal = () => this.safeSetState({ orderSuccessModalOpen: false });

    public render() {
        const { authModalOpen, orderSuccessModalOpen } = this.state;

        return (

            <section className="P-home-page">
                <Helmet script={[
                    helmetJsonLdProp<Person>({
                        "@context": "https://schema.org",
                        "@type": "Person",
                        name: "Grace Hopper",
                        alternateName: "Grace Brewster Murray Hopper",
                        alumniOf: {
                            "@type": "CollegeOrUniversity",
                            name: ["Yale University", "Vassar College"],
                        },
                        knowsAbout: ["Compilers", "Computer Science"],
                    }),
                ]}/>
                
                <Carousel />
                {!!Storage.profile && <MobileBonusCard />}
                <BrandsSlide/>
                <Collection />
                <AllProductsList params={{placementType:PlacementEnum.Pages,pageId:PageTypeEnum.Home}}/>
                <NewsSlide/>
                {orderSuccessModalOpen && <SuccessModal onClose={this.closeOrderSuccessModal}>
                    {Settings.guest ? 
                    <>
                        <h3>{Settings.translations.guest_order_success}</h3>
                        <button 
                            id={'sign_up'} 
                            className="G-main-button G-normal-link G-mt-30 P-register-button"
                            onClick={this.toggleAuthModal}
                        >
                            {Settings.translations.sign_up}
                        </button>
                    </> : 
                    <>
                        <h3>{Settings.translations.order_success}</h3>
                        <Link className="G-main-button G-normal-link G-mt-30"
                            to={ROUTES.PROFILE.ORDERS.MAIN}>{Settings.translations.order_history}</Link>
                    </>}
                </SuccessModal>}

                {authModalOpen && <Shared.Auth onClose={this.toggleAuthModal} />}
            </section>
        );
    }
}

export default Home;