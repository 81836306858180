import React, { useState, useEffect, useCallback } from 'react';
import './style.scss';
import Barcode from 'react-barcode';
import Settings from 'platform/services/settings';
import { SVGComponentRotateIcon } from 'components/svg-components';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import BonusCardController from 'platform/api/bonusCard';
import LogoGray from 'assets/images/logo.svg';
import { Link } from 'react-router-dom';
import ROUTES from 'platform/constants/routes';

const MobileBonusCard: React.FC = () => {
  const [isRotate, setIsRotate] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cardDetail, setCardDetail] = useState<any>({});

  const handleRotate = useCallback(() => {
    setIsRotate((prevIsRotate) => !prevIsRotate);
  }, []);

  const fetchBonusCardData = useCallback(async (loadPage?: boolean) => {
    if (!loadPage) {
      setIsLoading(true);
    }
    try {
      const result = await BonusCardController.GetBonusCardData();
      if (result.success) {
        setCardDetail((prevData: any) => ({
          ...prevData,
          CardDetail: result.data,
        }));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching bonus card data:', error);
    }
  }, []);

  useEffect(() => {
    fetchBonusCardData();
  }, [fetchBonusCardData]);

  return (
    <div className="bonusCardContainerHome">
      {Object.keys(cardDetail.CardDetail || {}).length !== 0 ? (
        <Link to={ROUTES.PROFILE.BONUS_CARD} className={`userBonusHome ${(cardDetail.CardDetail?.status === 'Locked') ? 'lockCard' : ''}`}>
          <img src={LogoGray} className="bonusCardImg" />

          <div className={`bonusCardMain`}>
            <div className="bonusCard back">
              <Barcode value={cardDetail.CardDetail?.cardNumber} format="CODE128" width={3} />
            </div>
          </div>
        </Link>
      ) : (
        <ShimmerLoading MClass={'bonusCardDetailHome'} colCount={1} childColCount={1} className={"productShimmer"} />
      )}
      
    </div>
  );
};

export default MobileBonusCard;
