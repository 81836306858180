import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import CheckBox from 'rc-checkbox';
import { sendIsCheckInputKey, sendIsRestoreKey } from 'platform/hooks/useStorage';
import { filterCase } from '../..';
import "./styles.scss"
let timerId: NodeJS.Timeout | null;

const DiscountFilter = ({ body, onChange, mobileFilterCase, onShowCase, hasDiscount }: any) => {
  const { allInLoading } = mobileFilterCase

  const [lessThan1200, setWindowWidth] = React.useState<any>(false);
  const [isChecked, setChecked] = React.useState<any>(!!body.hasDiscount);

  const toggleItem = (isClear: boolean) => {
    sendIsRestoreKey()
    if (!isClear) {
      sendIsCheckInputKey(filterCase.discount)
    }
    const bodyCopy = { ...body };
    if (timerId) {
      clearTimeout(timerId);
    }
    const query = new URLSearchParams(window.location.search);
    bodyCopy.hasDiscount ? query.delete('hasDiscount') : query.set('hasDiscount', "true")
    if (bodyCopy.hasDiscount) {
      bodyCopy.hasDiscount = false
    } else {
      bodyCopy.hasDiscount = true
    }
    window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);

    timerId = setTimeout(() => {
      onChange(bodyCopy);
    }, 300);
  }

  function handleWindowResize() {
    const windowWidth = window.innerWidth;
    setWindowWidth(windowWidth < 1200)
  }

  React.useEffect(() => { handleWindowResize()  }, [])
    
 

  React.useEffect(() => { 
    setChecked(hasDiscount)
    // toggleItem(false)
  }, [hasDiscount])

  return <div className='discount_checkbox'>
    <h3 className="P-row-title">{Settings.translations.Discounts}</h3>
    <label className="P-checkbox-row" onChange={() => {
        if (lessThan1200){onShowCase()}
        if (!allInLoading) {
          setChecked(!isChecked)
          toggleItem(false)
        }
    }}>
        <div className='check_box'>
            <CheckBox checked={isChecked} />
            <span className="P-name" title={"Discounts"}>{Settings.translations.Products_with}</span>
        </div>
    </label>
  </div>;
};

export default DiscountFilter;