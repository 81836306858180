import * as React from 'react';
import Slider from 'react-slick';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import BannerController from 'platform/api/banner';
import { IBannerListResponseModel } from 'platform/api/banner/models/response';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import { SamplePrevArrow } from '../arrowComponetn/prevArrow';
import { SampleNextArrow } from '../arrowComponetn/nextArrow';
import './style.scss';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';
import { BannerType } from 'components/header';

interface IState {
  data: IBannerListResponseModel[];
}

class Carousel extends HelperPureComponent<{}, IState> {

  public state: IState = {
    data: [],
  };

  public componentDidMount() { this.fetchData(); }

  private fetchData = async () => {
    const result = await BannerController.GetHeaderBanner(BannerType.Main);
    this.safeSetState({ data: result.data });
  }

  private onBannerItemClick = (item: any) => {
    const changeRoute = () => {
      const newUrl = `${item?.bannerDetailLink}`;
      window.routerHistory.replace(newUrl);
    };

    changeRoute()

    // const lang: any = Settings.language;
    // let url: string;

    // if (item.slugs !== null) {
    //   const urls = {
    //     [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId).replace(':slug', item.slugs[lang]),
    //     [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId).replace(':slug', item.slugs[lang]),
    //     [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
    //     [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
    //     [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
    //   };
    //   url = urls[item.type];
    // } else {
    //   const urls = {
    //     [BannerTypeEnum.Blog]: ROUTES.BLOG.DETAILS.replace(':id', item.dataId),
    //     [BannerTypeEnum.Product]: ROUTES.PRODUCTS.DETAILS.replace(':id', item.dataId),
    //     [BannerTypeEnum.Category]: `${ROUTES.PRODUCTS.MAIN}?categoryIds=${item.dataId}`,
    //     [BannerTypeEnum.Brand]: `${ROUTES.PRODUCTS.MAIN}?brandIds=${item.dataId}`,
    //     [BannerTypeEnum.ActiveIngredient]: `${ROUTES.PRODUCTS.MAIN}?activeIngredientIds=${item.dataId}`
    //   };
    //   url = urls[item.type];
    // }
    
    // window.routerHistory.replace(url);
  };

  private settings = {
    responsive: [
      {
        breakpoint: 700,
        settings: {
          swipe:true
        }
      }
    ]
  };

  public render() {
    const { data } = this.state;

    if(!data?.length) return <ShimmerLoading colCount={1} className={"ShimmerMainCoverSlider"}/>
    return (
      <div className='G-parent'>
        <Slider
          {...this.settings}
          dots={true}
          autoplay={false}
          autoplaySpeed={5000}
          slidesToShow={1}
          swipe={false}
          slidesToScroll={1}
          // arrows={true}
          nextArrow={<SampleNextArrow/>}
          prevArrow={<SamplePrevArrow/>}
          className="P-home-carousel-slider"
        >
          {data?.map((item:any,index) => {
            return (
              <div key={index}>
                <div onClick={() => this.onBannerItemClick(item)}>
                  <div className="P-home-carousel-slide bg-position-bottom">
                    <img alt='itemsImg' className='itemsImg' src={ResizerImgSizeDinamic(item.bannerWebImage,[1000,1000])}/>
                    {/* <ShadowText className="P-shadow-text-without-offset">{item.title}</ShadowText> */}
                  </div>
                </div>
              </div>
            )
          } )}
        </Slider>
      </div>
    );
  };
};

export default Carousel;