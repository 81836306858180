import * as React from 'react';

import NumberInput from '../number-input';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import './style.scss';
import { SVGComponentRemoveIcon } from 'components/svg-components';

interface IState {
  count: number;
  plused: boolean;
};

class NewCountInput extends HelperPureComponent<any, IState> {

  public state: IState = {
    count: 0,
    plused: false, 
  }

  public componentDidMount() {
    const { value } = this.props;
    this.safeSetState({ count: value });
  }

  private stepUp = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { count } = this.state;
    const { onChange, allowToStockQuantyty, CallShowAllowEdCount, item } = this.props;
    
    if(count < allowToStockQuantyty) {
      const value = count + 1;
      this.safeSetState({ count: value }, () => onChange(value));
    } else {
      CallShowAllowEdCount(item.id)
    }
  }

  private stepDown = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { count } = this.state;
    const { onChange,item } = this.props;
    if(+count > 1 ) {
      const value = count - 1;
      this.safeSetState({ count: value }, () => onChange(value,item.id));
    }

  }

  private stepDownIsDeleteAllow = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { count } = this.state;
    const { onChange} = this.props;
    if(count > 1 ) {
      this.safeSetState({ count: 1}, () => onChange(1));
    }
  }

  public render() {
    const { allowDeleteItemBasket, onDeleteBasket, isaddedtobasket, allowToStockQuantyty,onlyPlusIfOne } = this.props;
    const { count } = this.state;

    return (
      <div className={`P-count-input`}>
            {allowDeleteItemBasket && count === 1 && isaddedtobasket ? 
              <p className='count_plus_minus minus remove_basketItem' onClick={onDeleteBasket}><SVGComponentRemoveIcon/></p> :
              <span 
                className={`count_plus_minus minus ${(count === 1) ? "disabledMinus" : ''}`} 
                onClick={allowDeleteItemBasket ? this.stepDownIsDeleteAllow : this.stepDown}
              >-</span>}
            <NumberInput value={count.toString()} />
          <span className={`count_plus_minus plus ${count === allowToStockQuantyty || onlyPlusIfOne ? 'disabledMinus' : ''}`} onClick={this.stepUp}>+</span>
      </div>
    );
  }
}
export default NewCountInput;