import CategoryController from '../api/category';
import Settings from './settings';
import UserController from '../api/user';
import LanguageController from '../api/language';
import { ICategoryListResponseModel } from '../api/category/models/response';
import { ILanguageListResponseModel } from '..//api/language/models/response';
import AuthController from 'platform/api/auth';
import { IUserResponseModel } from 'platform/api/user/models/response';
import UserAddressController from 'platform/api/userAddress';
import DispatcherChannels from 'platform/constants/dispatcher-channels';


class Storage {
  public static fetchMainDefaultAddress = async () => {
    try {
      if (!Settings.guest) {
        const result = await UserAddressController.GetDefault();
        Storage.currentAddress = result?.data;
      }

      return true;
    } catch(e) { return false; }
  }
  
  public static fetchDefault = async () => {
    try {
      if (!Settings.token) {
        const guest = await AuthController.Guest();

        Settings.guest = guest.data.accessToken;
      } else if (!Settings.guest) {

        const profile = await UserController.Get();

        // if(!profile.success) {
          // localStorage.clear()
          // sessionStorage.clear()
          // window.location.reload() 
        // }
        Storage.profile = profile.data;
        window.dispatchEvent(new CustomEvent(DispatcherChannels.refreshCheckout));
      }
      
      const categories = await CategoryController.GetList();
      const languages = await LanguageController.GetList();
      Storage.categories = categories.data;
      Storage.languages = languages.data;

      return true;
    } catch(e) { 
      return false;
     }
  }

  public static profile: IUserResponseModel;
  public static categories: ICategoryListResponseModel[];
  public static languages:any = [
    {
      "id": 1,
      "isoCode": "hy",
      "name": "Հայ"
    },
    {
      "id": 2,
      "isoCode": "ru",
      "name": "Рус"
    },
    {
      "id": 3,
      "isoCode": "en",
      "name": "Eng"
    }
  ];
  public static currentAddress:any;

}

export default Storage;
