import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import Settings from 'platform/services/settings';
import { sendIsRestoreKey } from 'platform/hooks/useStorage';
let timerId: NodeJS.Timeout | null;
import './style.scss';

const PriceRange = ({ body, onChange, mobileFilterCase }: any) => {
    const { allInLoading } = mobileFilterCase
    const [value, setValue] = React.useState<[number, number]>([0, 0]);

    const changePrice = ([priceFrom, priceTo]) => {
        sendIsRestoreKey()

        const bodyCopy = { ...body };
        if (timerId) {
            clearTimeout(timerId);
        }
        const query = new URLSearchParams(window.location.search);
        bodyCopy.priceFrom = priceFrom;
        bodyCopy.priceTo = priceTo;
        query.set('priceTo', priceTo)
        query.set('priceFrom', priceFrom)

        window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        timerId = setTimeout(() => {
            onChange(bodyCopy);
        }, 300);
    }

    const changeInput = ([priceFrom, priceTo]: [number, number]) => {
        if (Number.isFinite(priceFrom) && Number.isFinite(priceTo)) {
            setValue([priceFrom, priceTo]);
            changePrice([priceFrom, priceTo]);
        }
    }

    React.useEffect(() => {
        if ((body?.priceFrom || body?.priceTo) && !allInLoading) {
            setValue([body?.priceFrom, body?.priceTo]);
        } 
    }, [body])

    return <div className='discount_main'>
        <h2>{Settings.translations.price}</h2>
        <div className="G-flex G-flex-justify-between P-price-range-inputs">
            <input className="G-main-input"
                value={value[0] || ''}
                onChange={(e) => changeInput([+e.target.value, value[1] as number])}
                placeholder={Settings.translations.from_Price}
            />
            <input className="G-main-input"
                value={value[1] || ''}
                onChange={(e) => changeInput([value[0] as number, +e.target.value])}
                placeholder={Settings.translations.to_Price}
            />
        </div>
    </div>
};

export default PriceRange;