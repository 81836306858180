import * as React from 'react';
import CountInput from 'components/count-input';
import Settings from 'platform/services/settings';
import { IProductDetailsResponseModel } from 'platform/api/product/models/response';
import HelperComponent from 'platform/classes/helper-component';
import BasketController from 'platform/api/basket';
import DispatcherChannels from 'platform/constants/dispatcher-channels';
import PharmaciesAvailablity from './components/pharmacies-availablity';
import { formatPrice } from 'platform/services/helper';
import ROUTES from 'platform/constants/routes';
import { PromotionTypeEnum } from 'platform/constants/enums';
import { IGetProductPromotionByQuantityModel } from 'platform/api/product/models/request';
import './style.scss';
import ConfirmModal from "../../../../../../../components/confirm-modal";
import UserController from "../../../../../../../platform/api/user";
import GtagData from "../../../../../../../platform/services/gtagData";
import { Shared } from 'modules';
import MainButton from 'components/Button/mainButton/mainButton';
import Storage from 'platform/services/storage';
import NewCountInput from 'components/count-input/newCountInput';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import Sidebar from 'modules/pages/home/components/all-products/components/suggestedItem';
import { PlacementEnum } from 'modules/pages/home/components/all-products';
import ProductController from 'platform/api/product';
import { CategoryPageTypeEnum } from '../../../list';

interface IProps {
  data: IProductDetailsResponseModel;
  onChange?(data: IProductDetailsResponseModel | null): void;
  type?: PromotionTypeEnum;
  fetchData:(callBack?:any) => void;
  mainLoading:boolean;
}

interface IState {
  count: number;
  price:number,
  discountPrice:number,
  cartLoading: boolean;
  isSelectedPackage: boolean;
  promotionModel: IGetProductPromotionByQuantityModel;
  pharmaciesAvailablityOpen: boolean;
  confirmModal: boolean;
  appWidth:boolean;
  authOpen:boolean;
  siderBarData:null;
  currentItemId:any,
};

class Info extends HelperComponent<IProps, IState> {

  public state: IState = {
    count: 1,
    price:1,
    discountPrice:1,
    cartLoading: false,
    pharmaciesAvailablityOpen: false,
    isSelectedPackage: false,
    confirmModal: false,
    siderBarData:null,
    currentItemId:null,
    promotionModel: {
      productId: null,
      quantity: 1,
      isPackage: false
    },
    appWidth: false,
    authOpen:false,
  };

  public componentDidMount() {
    const { data } = this.props;
    const promotionQuantities=data.packagePromotion.availablePromotions.promotionQuantities
    let { discountPrice, count, price} = this.state;

    price=data.price
    if(data.packagePromotion.promotionType>0) {
      discountPrice = data.packagePromotion.result
      price=data.packagePrice
    } else {
      discountPrice =data.packagePrice
      price=data.packagePrice
    }

    if (promotionQuantities.length > 0 ){
      if (promotionQuantities[0].percent === 50){
        count = 2;
        discountPrice=discountPrice * count
        price=price * count
      }
    }
    this.safeSetState({ price,count:data.basketCount, discountPrice });
  }

  private onCountChange = async (count: number) => {
    const { data } = this.props;
    let { discountPrice, price} = this.state;

    price=data.price*count
    discountPrice=data.price*count

    if(count > 0) this.safeSetState({ count, discountPrice, price});
    
    this.changeCart(count)

  }

  private changeCart = async (count?:any) => {
    const { data } = this.props;
    await BasketController.Change({
      productId: data.id,
      productQuantity:count || 1,
      isPackage: false,
    })
    GtagData(data,"add_to_cart")

    this.props.fetchData(() => {
      this.safeSetState({ cartLoading: false })
    })
    window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate))
  };

  private checkIsGuest= () => {
    const {mainLoading,data} = this.props
    if(Settings.guest) {
      this.setState({ authOpen: true});
    } else if(!Storage?.currentAddress) {
      window.dispatchEvent(new Event(DispatcherChannels.openAddress))
    } else if(!mainLoading) {
      const { count } = this.state;
      if(count === 0) this.safeSetState({ count:1 });
      this.safeSetState({ cartLoading: true });
      this.changeCart()
      this.handleOpenSideBar(data?.id)
    }
  }

  private togglePharmaciesAvailablity = () => {
    const { pharmaciesAvailablityOpen } = this.state;
    this.safeSetState({ pharmaciesAvailablityOpen: !pharmaciesAvailablityOpen });
  }

  private navigateToCategory = () => {
    const { id } = this.props.data.category;
    const query = new URLSearchParams(window.location.search);
    query.set('categoryIds', `${id}`);
    query.set('catTitleID', `${id}`);
    window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}&sortBy=${ProductSortEnum.PriceHighToLow}&page=1`);
  }

  private navigateToCategoryBrand = () => {
    const { data } = this.props;
    const { id } = this.props.data.category;
    const query = new URLSearchParams(window.location.search);
    query.set('brandIds', `${data.brand.id}`);
    window.routerHistory.push(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}&sortBy=${ProductSortEnum.PriceHighToLow}&page=1&CategoryPageType=${CategoryPageTypeEnum.brandPage}`);
  }

  private addSpecialProduct = async () => {
    const { data, onChange } = this.props;
    const result = await UserController.UpdatePreferredProductList({ newProductId: data.id });
    if (onChange) {
      result && result.success && onChange({
        ...data,
        isSpecial: !data.isSpecial,
      });
    }
    this.closeConfirmModal();
  }

  private closeConfirmModal = () => {
    this.safeSetState({ confirmModal: false });
  }

  private updateBasketCount = () => window.dispatchEvent(new CustomEvent(DispatcherChannels.CartItemsUpdate));
  
  private deleteBasketItem = async (item: any) => {
    const result = await BasketController.Delete(item.id, false);
    if (result.success) {
        this.updateBasketCount();
        GtagData(item,"remove_from_cart")
        this.props.fetchData();
    }
  };

  public async handleOpenSideBar(id?:number) {
    const { currentItemId } = this.state
    const result:any = await ProductController.GetProductsList({placementType:PlacementEnum.SuggestedProducts,pageId:id || currentItemId})
    if(result?.data?.length) { this.setState({siderBarData:result?.data,currentItemId:id || currentItemId}) }
  }

  public render() {
    const { data, mainLoading } = this.props;
    const { count, pharmaciesAvailablityOpen, confirmModal, authOpen, cartLoading, siderBarData} = this.state;
    const lang:any = Settings.language
    function IsAddedBasket() {
      return !!data.isAddedToBasket
    }
    const isDiscount = data?.discountedPrice < data.packagePrice;

    return (  
      <div className='P-product-ItemInfo'>
        <div className="P-product-details-info">
          <h2 className={`P-name`}>{data.title}</h2>
          <div className="G-ml-auto P-price">
            <span>{Settings.translations.Price_for_diteil}</span>
            {formatPrice(data.packagePrice)}
          </div>
          <h2 className='product_desc'>{Settings.translations.Product_information}</h2>

          <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.price_quantity}</span>
            <span className="P-value">
              <span className='G-cursor-text'>{data.unitQuantity} {data.unitName}</span>
            </span>
          </h3>
          {data.category && <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.category}</span>
            <span className="P-value" style={{color:"#02864B"}} onClick={this.navigateToCategory}>
              <span className='G-cursor-pointer'>{data.category.name}</span>
            </span>
          </h3>}
          {data?.producer?.name && <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.producer}</span>
            <span className="P-value">
              <span className='G-cursor-text'>{data.producer.name}</span>
            </span>
          </h3>}
          {data?.brand?.name && <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.brand}</span>
            <span className="P-value" style={{color:"#02864B"}} onClick={this.navigateToCategoryBrand}>
            <span className='G-cursor-pointer'>{data.brand.name}</span>
          </span>
          </h3>}
          {data.activeIngredients && !!data.activeIngredients.length && <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.active_ingredients}</span>
            <span className="P-value">
              <span className='G-cursor-text'>{data.activeIngredients.map(item => item.name).join(', ')}</span>
            </span>
          </h3>}
          <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.availability_at_the_nearest_pharmacy}</span>
            <span className="P-value P-pin G-cursor-pointer" onClick={this.togglePharmaciesAvailablity}>
              {Settings.translations.Find_stores}
            </span>
          </h3>
          {data.description && <h3 className="P-row">
            <span className='leftSIde'>{Settings.translations.description}</span>
            <p className="P-description">{data.description}</p>
          </h3>}
          
          {confirmModal && <ConfirmModal
              title={Settings.translations.special_product}
              text={Settings.translations.special_product_confirm}
              onConfirm={this.addSpecialProduct}
              onClose={this.closeConfirmModal}
          />}
        </div>
          
        <div className='right_counted_value'>
          <div className={`P-cart-actions-wrapper ${data.avgPrice ? 'P-avg-active' : ''}`}>
            <span className="G-ml-auto P-price">
              <span>{Settings.translations.Price_for_diteil}</span>
              <span className={`P-price ${isDiscount ? 'isDiscounted' : ''}`}>{formatPrice(data.discountedPrice)}</span>
               {isDiscount && <del className="P-without-discount-price">{formatPrice(data.packagePrice)}</del>}
            </span>
          </div>
          <div className="P-cart-actions">
            {(!data?.stockQuantity && Storage?.currentAddress) ? <h3 className="G-clr-red">{Settings.translations.out_of_stock}</h3> :
            <>
              <div className="P-count-info"><span className="P-selected-count-type">{data.unitName}</span></div>
              <div className='IsMobile'>
                  {IsAddedBasket() &&
                  <CountInput
                    min={data.minCount}
                    step={data.stepCount}
                    value={count}
                    onChange={this.onCountChange}
                    allowDeleteItemBasket={true}
                    onDeleteBasket={() => this.deleteBasketItem(data)}
                    isaddedtobasket={!!data.basketCount}
                    allowToStockQuantyty={count < data?.stockQuantity && data?.stockQuantity > 1}
                  />}
                  {!IsAddedBasket() && <MainButton disabledLoad={mainLoading} loading={cartLoading} onClick={this.checkIsGuest}/>}
              </div> 

              <div className='IsDesktop'>
                {IsAddedBasket() && <CountInput
                  min={data.minCount}
                  step={data.stepCount}
                  value={count}
                  onChange={this.onCountChange}
                  allowDeleteItemBasket={true}
                  onDeleteBasket={() => this.deleteBasketItem(data)}
                  isaddedtobasket={!!data.basketCount}
                  allowToStockQuantyty={count < data?.stockQuantity && data?.stockQuantity > 1}
                  onlyPlusIfOne={true}
                />}
                <MainButton loading={cartLoading} disabledLoad={mainLoading} onClick={this.checkIsGuest} isaddedtobasket={!!data.isAddedToBasket}/>
              </div>
            </>}
          </div>
        </div>

        {pharmaciesAvailablityOpen && <PharmaciesAvailablity onClose={this.togglePharmaciesAvailablity} data={data}/>}
        {authOpen && <Shared.Auth onClose={() => this.setState({ authOpen: false})}/>}
        <Sidebar
          lang={lang}
          siderBarData={siderBarData} 
          onClose={() => this.setState({siderBarData:null})} 
          handleUpdate={(id:number) => this.handleOpenSideBar()}
        />                 
      </div>
    );
  };
};

export default Info;
