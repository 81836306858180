import * as React from 'react';
import Barcode from 'react-barcode';
import HelperComponent from 'platform/classes/helper-component';
import { byPrivateRoute } from 'platform/decorators/routes';
import ROUTES from 'platform/constants/routes';
import Layout from '../../components/layout';
import Settings from 'platform/services/settings';
import BonusCardController from 'platform/api/bonusCard';
import { onlyForUsers } from 'platform/guards/routes';
import LogoGray from 'assets/images/logo.svg';

import './style.scss';
import {Link} from "react-router-dom";
import { SVGBlockIcon, SVGComponentBackToIcon, SVGComponentRegisterIcon, SVGComponentRotateIcon, SVGComponentTargetIcon, SVGComponentTransactionDownIcon, SVGComponentTransactionUpIcon, SVGPinCodeIcon, SVGReplaceCardIcon } from 'components/svg-components';
import moment from 'moment';

import ClickOutside from 'components/click-outside';
import ScrollPageLoader from 'components/page-loader/scrollLoader';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import EmptyStateTransaction from 'components/empty-state/transactionEmptyState';

enum popUpKey {
  close = 0,
  pin = 1,
  recovery = 2,
  block = 3,
  replace = 4,
  okPopUP = 5,
  aboutUs = 6,
  blockOkPopUP = 7,
};

enum BonusCardStatusType {
  Created,
  Activated,
  Deactivated,
  Blocked,
  Locked,
}
interface IState {
  data?: any;
  isLoading: boolean;
  smallMedia: boolean;
  isRotate:boolean;
  isOpenPopUp:boolean;
  isOpenPopUpAll:number;
  replaceVlue:string;
  optValue:string;
  focusInput:boolean;
  submitHandler:boolean
};

@byPrivateRoute(ROUTES.PROFILE.BONUS_CARD, [onlyForUsers])
class BonusCard extends HelperComponent<IState, {}> {

  public state: IState = {
    isLoading: true,
    smallMedia: false,
    isRotate:false,
    isOpenPopUp:false,
    isOpenPopUpAll:0,
    replaceVlue:"",
    optValue:"",
    submitHandler:false,
    focusInput:false,
    data:{
      Transaction:{},
      CardDetail:{},
    }
  };
  
  public componentDidMount() {
    if (window.innerWidth <= 900) {
      this.safeSetState({smallMedia: true})
    }
    this.fetchTransactionData(1)
    this.fetchBonusCardData()
  }

  private fetchTransactionData = async (page:number) => {
    BonusCardController.GetTransaction(page).then((result) => {
      if(result.success) {
        this.setState(() => ({
          data: {
            ...this.state.data,
            Transaction: result.data,
          },
        }));
      }
    });
  }

  private fetchBonusCardData = async (loadPage?:boolean) => {
    if(!loadPage) {
      this.setState({isLoading:true})
    }
    BonusCardController.GetBonusCardData().then((result:any) => {
      if(result.success) {
        this.setState(() => ({
          isLoading:false,
          data: {
            ...this.state.data,
            CardDetail: result.data,
          },
        }));
      }
    });
  }

  private handleRotate:any = async () => {
    this.setState({isRotate: !this.state.isRotate})
  }

  private handleIsOpenPopUp:any = async () => {
    // if(!this.state.isOpenPopUp) {
      this.setState({isOpenPopUp: !this.state.isOpenPopUp})
    // }
  }

  private handleIsOpenPopUpAll:any = async (key:number) => {
    if(key === 0) {
      document.body.style.overflow = 'initial';
      this.setState({isOpenPopUpAll: key, optValue:"",replaceVlue:""})
    } else {
      document.body.style.overflow = 'hidden';
      this.setState({isOpenPopUpAll: key,isOpenPopUp:false,submitHandler:false})
    }
  }

  private handleReplaceBonusCard:any = async (key:number) => {
    const body = {
      "cardNumber": this.state.replaceVlue,
      "pin": this.state.optValue,
    }
    
    BonusCardController.GetBonusCardReplace(body).then((result) => {
      if(result.success) {
        this.setState(() => ({
          replaceVlue:'',
          optValue: '',
        }));
        this.handleIsOpenPopUpAll(5)
        this.fetchBonusCardData()
      }
    });
  }

  private handlelockBonusCard:any = async (key:number) => {
    const body = {
      "cardNumber": this.state.data.CardDetail.cardNumber,
      "pin": this.state.optValue,
    }
    
    BonusCardController.GetlockBonusCard(body).then((result) => {
      if(result.success) {
        this.setState(() => ({
          optValue: '',
        }));
        this.handleIsOpenPopUpAll(7)
        this.fetchBonusCardData(true)
      }
    });
  }

  private handleUnlockBonusCard:any = async (key:number) => {
    const body = {
      "cardNumber": this.state.data.CardDetail.cardNumber,
      "pin": this.state.optValue,
    }
    
    BonusCardController.GetUnlockBonusCard(body).then((result) => {
      if(result.success) { 
        this.setState(() => ({
          optValue: '',
        }));
        this.handleIsOpenPopUpAll(7)
        this.fetchBonusCardData(true)
      }
    });
  }

  private changeReplaceValue:any = async (event:any) => {
    this.setState({replaceVlue: event.target.value})
  }

  private classnameInputCode:any = (inputColoredRed:any,focusInput:any,val:any,index:any,arr:any) => {
    return  `inputeCode 
      ${inputColoredRed ? 'coloredRed' : ''} 
      ${(focusInput === true) && ((val.length === index) || ((val.length - 1 === index) && (index === arr.length - 1))) ? "activeInput" : ""}` 
  };

  public render() {
    const { data, isRotate, isOpenPopUp, isOpenPopUpAll, optValue, focusInput } = this.state;
    const { Transaction,CardDetail } = data

    return ( 
      <Layout>
        <div className='bonusCardContainer'>
          {Object.keys(CardDetail).length !== 0 ? (
            <div className='titleandUserBonusContainer'>
              <div className='titleBonusCard'>
                <p className="title">{Settings.translations.bonus_card}</p>
                <button className='openPopUpListButton' onClick={this.handleIsOpenPopUp}>
                  <SVGComponentRegisterIcon/>
                  <span className='TextButton'>{Settings.translations.Manage}</span>
                </button>
              </div>
            
              <div className={`userBonus ${(BonusCardStatusType.Locked === CardDetail.status) ? 'lockCard' : ''}`}>
                <button className='rotateBonusCode' onClick={this.handleRotate}>
                  <SVGComponentRotateIcon/>
                </button>
                
                <div className='cardDataContainer'>
                  <div className='cardData'>
                      <span>{Settings.translations.CardOwner}</span>
                      <span className='cardName'>{CardDetail.fullName}</span>
                  </div>
                  
                  <div className='cardData'>
                      <span>{Settings.translations.CardBalance}</span>
                      <span>{CardDetail.balance}</span>
                  </div>

                  <div className='cardData'>
                      <span>{Settings.translations.a_CardNumber}</span>
                      <span>{CardDetail.cardNumber}</span>
                  </div>
                </div>
                
                <div className={`bonusCardMain ${isRotate ? 'bonusCardRotateMain' : ''}`}>
                  <div className='bonusCard front'>
                    <img src={LogoGray} className='bonusCardImg'/>
                    <p className='BonusCardText'>{Settings.translations.bonusCardText}</p>
                  </div>
                  <div className='bonusCard back'>
                    <Barcode value={CardDetail.cardNumber} format="CODE128" width={3} />
                  </div>
                </div>
              </div>
            </div>
          ) : <ShimmerLoading MClass={'bonusCardDetail'} colCount={1} childColCount={3} className={"productShimmer"}/>}
          
          {!!Transaction?.itemCount ? 
          <div className='historyTransaction'>
            <p className='historyText'>{Settings.translations.Transactions}</p>
            <div className='transactionList'>
              {Transaction?.data?.map(({amount,createdDt,orderId}) => { 
                return (
                  <div key={orderId} className='transactionsItem'>
                    <div className='leftTitleField'>
                      <div className='imgContainer'>
                        {amount > 0 ? <SVGComponentTransactionUpIcon/> : <SVGComponentTransactionDownIcon/>}
                      </div>
                      <div className='textFieldTransaction'>
                        <p className='transactionDate'>{moment.utc(createdDt).local().format('DD MMM YYYY HH:mm')}</p>
                        <div className='codeAddresContainer'>
                            <p className='orderText'>{Settings.translations.order}</p>
                            <p className='orderNumber'>#{orderId}</p>
                            <Link to={ROUTES.PROFILE.ORDERS.DETAILS.replace(':id', orderId)} className='orderDetailButton'>
                              {Settings.translations.DetailText}
                            </Link>
                        </div>
                      </div>
                    </div>
                    <div className='PriceField'>
                      {
                        amount > 0 ? 
                        <div className='bonusplus'>
                          <span className='bonusplus'>{"+"}</span>
                          <span className='bonusPrice'>{amount}</span>
                        </div> :
                        <div className='bonusMinus'>
                          {/* <span className='bonusplus'>{"-"}</span> */}
                          <p className='bonusPrice'>{amount}</p>
                        </div>
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          </div> : !!Transaction?.itemCount?.length ? <ShimmerLoading MClass={'bonusTransactionDetail'} colCount={1} childColCount={6} className={"productShimmer"}/> : <EmptyStateTransaction/>}
          
          <ClickOutside onClickOutside={() => {
            if(isOpenPopUpAll === 0) {
              this.setState({isOpenPopUp: false})
            }
          }}>
            <div className='popUpBonus' onClick={this.handleIsOpenPopUp} style={{display:isOpenPopUp ? 'flex' : 'none'}}>
                <div className='popUpInner' onClick={(e) => e.stopPropagation()}>
                    <button className='aboutBonusCard aboutText' onClick={() => this.handleIsOpenPopUpAll(6)}>
                      <SVGComponentRegisterIcon/>
                      <p  className='textPopUp'>{Settings.translations.About_bonus_card}</p>
                    </button>
                    <button className='aboutBonusCard' onClick={() => {
                      sessionStorage.setItem('backRouteKey',`1`)
                      this.handleIsOpenPopUpAll(1)
                    }}>
                      <SVGPinCodeIcon/>
                      <p  className='textPopUp'>{Settings.translations.Pin_recovery}</p>
                    </button>
                    <button className='aboutBonusCard' onClick={() => {
                      sessionStorage.setItem('backRouteKey',`2`)
                      this.handleIsOpenPopUpAll(2)
                    }}>
                      <SVGReplaceCardIcon/>
                      <p  className='textPopUp'>{Settings.translations.Replace_card}</p>
                    </button>
                    <button className='aboutBonusCard' onClick={() => {
                      sessionStorage.setItem('backRouteKey',`3`)
                      this.handleIsOpenPopUpAll(3)
                    }}>
                      <SVGBlockIcon/>
                      <p className='textPopUp'>{BonusCardStatusType.Locked === CardDetail.status ? Settings.translations.UnLock_card : Settings.translations.Lock_card}</p>
                    </button>
                </div>
            </div>
          </ClickOutside>

          <div className='PopUpMainContainer' onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)} style={{display:isOpenPopUpAll ? 'flex' : 'none'}}>
            <div className='popUpPin screenAll' style={{display:isOpenPopUpAll === popUpKey.pin ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <div className='backToMenu' onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}>
                <SVGComponentBackToIcon/>
                <p className='menuTitle'>{Settings.translations.back_menu}</p>
              </div>
              <h1 className='title'>{Settings.translations.Pin_recovery}</h1>
              <p className='textPopUp'>{Settings.translations.pinTecoveryText}</p>
              <button className='closePopUp' onClick={() => {
                const backRoutePopUp = sessionStorage.getItem("backRouteKey")
                const backToIndex = Number(backRoutePopUp) === 1 ? popUpKey.close : Number(backRoutePopUp) === popUpKey.block ? popUpKey.block : popUpKey.replace
                this.handleIsOpenPopUpAll(backToIndex)
              }}>{Settings.translations.ClosePage}</button>
            </div>

            <div className='popUpPin screenAll replaceMain' style={{display:isOpenPopUpAll === popUpKey.recovery ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <button className='closeButton'>
                <i className="P-close icon-Group-5032" onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)} />
              </button>
              <div className='backToMenu' onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}>
                <SVGComponentBackToIcon/>
                <p className='menuTitle'>{Settings.translations.back_menu}</p>
              </div>
              <h1 className='title'>{Settings.translations.Replace_your_bonus_card_text}</h1>
              <p className='textPopUp'>
                {Settings.translations.write_ReplaceCardText.replace("№",`№${CardDetail.cardNumber}`)}
              </p>
              <p className='childTetx'>{Settings.translations.write_BonusCard}</p>

              <div className='G-input-country-code-main'>
                <p className='titleHolder'>{Settings.translations.bonus_card}</p>
                <input
                  name="username"
                  value={this.state.replaceVlue}
                  placeholder={Settings.translations.Bonus_card_number}
                  className={`G-main-input ${!this.state.replaceVlue && this.state.submitHandler ? 'G-invalid-field' : ''}`}
                  onChange={this.changeReplaceValue}
                />
              </div>

              <button className='closePopUp nextButton' onClick={() => {
                if(this.state.replaceVlue) {
                  this.handleIsOpenPopUpAll(4)
                } else {
                  this.setState({submitHandler:true})
                }
              }}>{Settings.translations.next}</button>
            </div>

            <div className='popUpPin screenAll block' style={{display:isOpenPopUpAll === popUpKey.block ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <button className='closeButton'>
                <i className="P-close icon-Group-5032" onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)} />
              </button>
              <div className='backToMenu' onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}>
                <SVGComponentBackToIcon/>
                <p className='menuTitle'>{Settings.translations.back_menu}</p>
              </div>
              <h1 className='title'>{BonusCardStatusType.Locked === CardDetail.status ? Settings.translations.ulock_your_bonus_card : Settings.translations.lock_your_bonus_card}</h1>
              <p className='textPopUp'>
                {BonusCardStatusType.Locked === CardDetail.status ? Settings.translations.UnlockenterPinCardCard : Settings.translations.enterPinCardCard}
              </p>

              <div className="input_container">
                <input 
                  className="writeCode"
                  maxLength={4} 
                  value={optValue}
                  onChange={(e:any) => {if(!isNaN(e.target.value)){this.setState({optValue:e.target.value})}}}
                  onFocus={(e) => this.setState({focusInput:true})}
                  onBlur={(e) => this.setState({focusInput:false})}
                />
                {new Array(4).fill("").map((c,index,arr) => {
                  return <span key={index} 
                      className={this.classnameInputCode((!this.state.optValue && this.state.submitHandler),focusInput,optValue,index,arr)}>
                      {(optValue.length > index) ? optValue[index] : ""}
                  </span>
                })}
              </div>

              <p className='childTetx' onClick={() => this.handleIsOpenPopUpAll(1)}>{Settings.translations.Forgot_my_PIN_code}</p>
              <button className='closePopUp blockButton'
                onClick={() => {
                  if(this.state.optValue) {
                    if(BonusCardStatusType.Activated === CardDetail.status) {
                      this.handlelockBonusCard()
                    } else if(BonusCardStatusType.Locked === CardDetail.status) {
                      this.handleUnlockBonusCard()
                    }
                  } else {
                    this.setState({submitHandler:true})
                  }
              }}
              >{BonusCardStatusType.Locked === CardDetail.status ? Settings.translations.ulock_your_bonus_card : Settings.translations.Lock_card}</button>
            </div>

            <div className='popUpPin screenAll replaceOptNumber' style={{display:isOpenPopUpAll === popUpKey.replace ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <div className='backToMenu' onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}>
                <SVGComponentBackToIcon/>
                <p className='menuTitle'>{Settings.translations.back_menu}</p>
              </div>
              <h1 className='title'>{Settings.translations.Replace_your_bonus_card_text}</h1>
              <p className='textPopUp'>
                {Settings.translations.Replace_your_bonus_card_Description}
              </p>

              <div className="input_container">
                <input className="writeCode"
                    maxLength={4} 
                    value={optValue}
                    onChange={(e:any) => {if(!isNaN(e.target.value)){this.setState({optValue:e.target.value})}}}
                    onFocus={(e) => this.setState({focusInput:true})}
                    onBlur={(e) => this.setState({focusInput:false})}
                />
                {new Array(4).fill("").map((c,index,arr) => {
                    return <span key={index} 
                        className={this.classnameInputCode((!this.state.optValue && this.state.submitHandler),focusInput,optValue,index,arr)}>
                        {(optValue.length > index) ? optValue[index] : ""}
                    </span>
                })}
              </div>

              <p className='childTetx' onClick={() => this.handleIsOpenPopUpAll(1)}>{Settings.translations.Forgot_my_PIN_code}</p>
              <div className='buttonContainer'>
                <button className='closePopUp replaceButtonClose' 
                  onClick={() => this.handleIsOpenPopUpAll(2)}
                >{Settings.translations.Back}</button>
                <button className='closePopUp replaceButton' 
                  onClick={() => {
                    if(this.state.optValue) {
                      this.handleReplaceBonusCard()
                    } else {
                      this.setState({submitHandler:true})
                    }
                  }}
                >{Settings.translations.Replace}</button>
              </div>
            </div>
            
            <div className='popUpPin screenAll' style={{display:isOpenPopUpAll === popUpKey.okPopUP ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <SVGComponentTargetIcon/>
              <h1 className='title'>{Settings.translations.succesBonusCardChangeText}</h1>
              
              <div className='buttonContainer'>
                <button className='closePopUp replaceButtonClose' 
                  onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}
                >{Settings.translations.ClosePage}</button>
              </div>
            </div>

            <div className='popUpPin screenAll OkLockPopUp' style={{display:isOpenPopUpAll === popUpKey.blockOkPopUP ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <SVGComponentTargetIcon/>
              <h1 className='title'>{BonusCardStatusType.Locked === CardDetail.status ? Settings.translations.lockBonusCardChangeText : Settings.translations.UnlockBonusCardChangeText}</h1>
              
              <div className='buttonContainer'>
                <button className='closePopUp replaceButtonClose' 
                  onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}
                >{Settings.translations.ClosePage}</button>
              </div>
            </div>
            
            <div className='popUpPin screenAll aboutUs' style={{display:isOpenPopUpAll === popUpKey.aboutUs ? 'flex' : 'none'}} onClick={(e) => e.stopPropagation()}>
              <div className='backToMenu' onClick={() => this.handleIsOpenPopUpAll(popUpKey.close)}>
                <SVGComponentBackToIcon/>
                <p className='menuTitle'>{Settings.translations.back_menu}</p>
              </div>
              <h1 className='aboutTitle'>{Settings.translations.abouis_natali_pharm}</h1>
              <div className='bonusCard'>
                <img src={LogoGray} className='bonusCardImg'/>
                <p className='BonusCardText'>{Settings.translations.bonusCardText}</p>
              </div>
              <div className='description'>
                {Settings.translations.abouisDescription_natali_pharm}
              </div>
            </div>
          </div>
        </div>
        {/* <Pagination<IBonusCardHistoryResponseModel> fetchData={this.fetchData} /> */}
      </Layout>
    );
  }
}

export default BonusCard;
