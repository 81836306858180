import Settings from "platform/services/settings";
import React from "react";
import "./style.scss"
import ROUTES from "platform/constants/routes";
import PageLoader from "components/page-loader";
interface IProps {
    type?: string;
    isaddedtobasket?:boolean;
    onClick?(e: React.SyntheticEvent<HTMLElement>): void;
    text?:string;
    loading?:boolean;
    disabledLoad?:boolean;
}

export default function MainButton({isaddedtobasket,onClick,text,loading,disabledLoad}:IProps) {

    function handelChangeRoute(e:any) {
        e.preventDefault()
        window.routerHistory.push(ROUTES.CART);
    }
    
    if(isaddedtobasket) {
        return (
            <button className="mainButton isAddedToBasket" onClick={handelChangeRoute}>
                <span className="textMainButton">{text || Settings.translations.edit_to_cart_short}</span>
                <span className="textMainShort">{text || Settings.translations.added}</span>
            </button>
        )
    }
    
    return (
        <button className="mainButton" onClick={onClick} disabled={disabledLoad}
            onMouseDown={(e) => e.stopPropagation()} // Prevent triggering parent `onMouseDown`
            onMouseUp={(e) => e.stopPropagation()}   // Prevent triggering parent `onMouseUp`
        >
            {loading ? <PageLoader /> : (
                <>
                    <span className="textMainButton">{text ||Settings.translations.add_to_cart}</span>
                    <span className="textMainShort">{text || Settings.translations.add_to_cart_short}</span>
                </>
            )}
        </button>
    )
}