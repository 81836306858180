import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ClickOutside from '../../../click-outside';
import ROUTES from 'platform/constants/routes';
import { Link } from 'react-router-dom';
import Settings from 'platform/services/settings';
import './index.scss';
import BrandController from 'platform/api/brand';
import { CollectionPromo } from '../promoLink';
import { ProductSortEnum } from 'platform/api/product/constants/enums';

interface IProps {
  onClose(): void;
}

interface PromoCollection {
  id: number;
  title: string;
}

const MobileHeaderPopUP: React.FC<IProps> = ({ onClose }) => {
  const [promoCollections, setPromoCollections] = useState<PromoCollection[]>([]);

  // Fetch promo data with proper error handling
  const fetchData = useCallback(async () => {
    try {
      const result = await BrandController.GetPromoHeaderList(CollectionPromo.Promo);
      if (result?.data?.promoCollections) {
        setPromoCollections(result.data.promoCollections);
      } 
    } catch (error) {
      console.error('Error fetching promo collections:', error);
    } 
  }, []);

  // Call fetchData on mount
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Memoize the `activePromo` class based on the current URL
  const getActivePromoClass = useMemo(() => {
    const query = new URLSearchParams(window.location.search);
    const CollectionId = query.get('CollectionId');
    return (id: number) => (Number(CollectionId) === id ? 'activePromo' : '');
  }, []);

  // Navigate to a different route
  const changeRoute = (id: number) => {
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?CollectionId=${id}&sortBy=${ProductSortEnum.PriceHighToLow}&page=1`;
    window.location.href = newUrl;
  };

  return (
    <div className="main_categori_popUp">
      <ClickOutside onClickOutside={onClose}>
        <div className="P-header-categories-wrapper">
          {promoCollections.map(({ title, id }) => (
            <button
              key={id}
              className={`P-link ${getActivePromoClass(id)}`}
              onClick={() => changeRoute(id)}
            >
              {title}
            </button>
          ))}
        </div>
      </ClickOutside>
    </div>
  );
};

export default MobileHeaderPopUP;