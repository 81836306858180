// export default {
//   BASE_URL: "https://qapapi.armlon.co.uk/",
// };

// import * as CONFIG from '../../../config.json';
// https://api.Natali.am/
// https://nataliapi.innodream.com/
export default {
    BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://nataliapi.innodream.com/" : "https://nataliapi.innodream.com/",
    BASE_URL_CITY: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://apishopv2.yerevan-city.am/" : "https://apishopv2.yerevan-city.am/",
    // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "http://localhost:5000/" : "http://localhost:5000/",
    // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://api.Natali.am/" : "https://Nataliback.abmdemo.me/",
    // BASE_URL: (process.env.RAZZLE_ENV || '').trim() === 'staging' ? "https://Natali.dev-15.toolbox.am/" : "https://Natali.dev-15.toolbox.am/",
    // SITE_URL:'https://natalionline.am/',
    // SITE_URL:'http://localhost:3000/',
    SITE_URL:'https://natalionline.am/',
    PROMOCODE_NAME: 'visatest10',

    WHOLESALE: false,
    SeasonalOffersId:0,
    OthersProductsId:212,
    RecommendedProductsId:18524,
    ProductOfTheDay:18552,
    childOffers:206,
    cosmeticsProductsID:205,
    medicalProductsId:204
};
