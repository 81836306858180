import * as React from 'react';

import Modal from '../modal';
import xxx from '../../assets/images/xxx.png'

import './style.scss';
import { Link } from 'react-router-dom';

interface IProps {
  onClose(): void;
  children: string | React.ReactNode;
  link?: {
    path: string;
    name: string;
  };
  bool1?: boolean;
  bool2?: boolean;
}
const SuccessModal = React.memo(({ onClose, children, link, bool1, bool2}: IProps) => (
  <Modal onClose={onClose} className="P-success-modal">
    {bool2 && <img style={{width:'60px'}} src={xxx} alt=""/>}
    {bool1 && <i className="icon-Group-5535"/>}
    {children}
    {!!link && <Link to={link.path} className="G-main-button P-link-item G-full-width G-no-text-decoration G-text-center">{link.name}</Link>}
  </Modal>
));

export default SuccessModal;
