import {OrderStatusEnum, PrescriptionsEnum} from 'platform/api/order/constants/enums';

export const statusColorClassNames = {
  [OrderStatusEnum.Pending]: 'G-clr-Pending',
  [OrderStatusEnum.Scheduled]: 'G-clr-Scheduled',
  [OrderStatusEnum.Collecting]: 'G-clr-Collecting',
  [OrderStatusEnum.Delivering]: 'G-clr-Delivering',
  [OrderStatusEnum.Finished]: 'G-clr-Finished',
  [OrderStatusEnum.Canceled]: 'G-clr-Canceled',
};

export const statusColorClassNamesNew = {
  [OrderStatusEnum.Pending]: 'G-clr-Pending',
  [OrderStatusEnum.Scheduled]: 'G-clr-Scheduled',
  [OrderStatusEnum.Collecting]: 'G-clr-Collecting',
  [OrderStatusEnum.Delivering]: 'G-clr-Delivering',
  [OrderStatusEnum.Finished]: 'G-clr-Finished',
  [OrderStatusEnum.Canceled]: 'G-clr-Canceled',
};

export const statusColorPrescriptionsClassNames = {
  [PrescriptionsEnum.Delivered]: 'G-clr-green',
  [PrescriptionsEnum.Failed]: 'G-clr-red',
  [PrescriptionsEnum.Pending]: 'G-clr-orange',
};