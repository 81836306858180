import React, { useState } from 'react';
import Settings from 'platform/services/settings';
import { IContactUsBodyModel } from 'platform/api/support/models';
import SupportController from 'platform/api/support';
import Storage from 'platform/services/storage';

interface IFormValidation {
  valid: boolean;
  errors: Record<string, string>;
}

const Form: React.FC = () => {
  const initialBody: IContactUsBodyModel = {
    firstName: '',
    lastName: '',
    phone: '',
    content: '',
    email: '',
  };

  const [body, setBody] = useState<IContactUsBodyModel>(initialBody);
  const [submitted, setSubmitted] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  function hasEmptyFields(obj: Record<string, string>): boolean {
    return Object.values(obj).some((value) => {
      const valid = value.trim() === ''
      return valid
    });
  }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  function isValidPhone(phone: string): boolean {
    const phoneRegex = /^\+(\d{1,3})\d{4,14}$/;
    return phoneRegex.test(phone);
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBody((prevBody) => ({ ...prevBody, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitted(true);
  
    const newBody = {
      name: `${body.firstName} ${body.lastName}`,
      email: body.email,
      content: body.content,
      phone: body.phone,
    };
  

    if (hasEmptyFields(newBody)) {
      console.error('Some fields are empty!');
      return; // Prevent submission if any field is empty
    }
  
    if (!isValidEmail(newBody.email)) {
      console.error('Invalid email format!');
      return; // Prevent submission if email is invalid
    }
  
    if (!isValidPhone(newBody.phone)) {
      console.error('Invalid phone format!');
      return; // Prevent submission if phone is invalid
    }
  
    setSubmitLoading(true);
    try {
      const res = await SupportController.createRequest(newBody);
      if (res.success) {
        const alertify = await import('alertifyjs');
        alertify.success(`
          <div class="MuiAlert_standardError_child">
            <strong>Success</strong>
            ${Settings.translations.request_success}
          </div>
        `);
  
        setBody(initialBody); // Reset form fields
        setSubmitted(false); // Reset submitted state
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <form className="contact-us-form" onSubmit={handleSubmit}>
      <div className="name_field">
        <div className="G-main-form-field">
          <p className="placeholder">{Settings.translations.first_name}</p>
          <input
            type="text"
            name="firstName"
            value={body.firstName}
            onChange={handleChange}
            className={`contact-input ${!body.firstName && submitted ? 'G-invalid-field' : ''}`}
          />
        </div>

        <div className="G-main-form-field">
          <p className="placeholder">{Settings.translations.last_name}</p>
          <input
            type="text"
            name="lastName"
            value={body.lastName}
            onChange={handleChange}
            className={`contact-input ${!body.lastName && submitted? 'G-invalid-field' : ''}`}
          />
        </div>
      </div>
      <div className="G-main-form-field">
        <p className="placeholder">{Settings.translations.email}</p>
        <input
          type="email"
          name="email"
          value={body.email}
          onChange={handleChange}
          className={`contact-input ${!body.email && submitted ? 'G-invalid-field' : ''}`}
        />
      </div>
      <div className="G-main-form-field">
        <p className="placeholder">{Settings.translations.phone_number_only}</p>
        <input
          type="tel"
          name="phone"
          value={body.phone}
          onChange={handleChange}
          className={`contact-input ${!body.phone && submitted? 'G-invalid-field' : ''}`}
        />
      </div>
      <div className="G-main-form-field">
        <p className="placeholder">{Settings.translations.write_a_message}</p>
        <textarea
          name="content"
          value={body.content}
          onChange={handleChange}
          className={`G-main-textarea ${!body.content && submitted ? 'G-invalid-field' : ''}`}
        />
      </div>
      <button
        type="submit"
        className={`G-main-button P-form-button ${submitLoading ? 'loading' : ''}`}
        id="send_btn"
      >
        {submitLoading ? Settings.translations.send : Settings.translations.send}
      </button>
    </form>
  );
};

export default Form;
