import * as React from 'react';

import { ProductSortEnum } from 'platform/api/product/constants/enums';
import { buildFilters } from '../../services/helper';
import Select from 'components/select';
import { SortByDropdown } from 'platform/constants/dropdowns';
import ROUTES from 'platform/constants/routes';
import slackIcon from "assets/images/icons/ic_arrow.svg"
import './style.scss';
import { SVGFilterIcon } from 'components/svg-components';
import { sendIsRestoreKey } from 'platform/hooks/useStorage';
import Storage from 'platform/services/storage';
import CategoryController from 'platform/api/category';
import Settings from 'platform/services/settings';

interface IProps {
  onChange(): void;
  AllData?:any
  openFilter():void,
  categoryTitleOutside:any
  itemCount:any
};

const SortBox = ({onChange,openFilter,AllData,categoryTitleOutside,itemCount}: IProps) => {
    const filter = buildFilters();
    const [sortBy, setSortBy] = React.useState(ProductSortEnum.PriceHighToLow);
    const [categoryTitle, setCategoryTitle] = React.useState('');


    React.useEffect(() => {
        const changedSortBy = !filter.hasDiscount &&
        (filter.sortBy === ProductSortEnum.PriceHighToLow || 
        filter.sortBy === ProductSortEnum.PriceHighToLow) ? ProductSortEnum.PriceHighToLow : filter.sortBy;
        changedSortBy && changeSortBy(changedSortBy);
    }, []);

    React.useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const catTitleID = query.get('catTitleID');
        if(catTitleID) {
            (async () => {
                const result = await CategoryController.GetListTitle(Number(catTitleID));
                setCategoryTitle(result?.data?.name)
            })();
        } else if(categoryTitleOutside){
            setCategoryTitle(categoryTitleOutside)
        } else {
            setCategoryTitle(Settings.translations.products)
        }
    }, [AllData]);

    const changeSortBy = (chosen: ProductSortEnum) => {
        setSortBy(chosen);
        const query = new URLSearchParams(window.location.search);
        query.set('sortBy', chosen.toString());
        // window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        window.history.replaceState(null,'',`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);

        sendIsRestoreKey()
        onChange();
    }   

    return (
        <div className="P-products-sort-box">
            <h3 className="G-page-title-left G-h3-calc"><p>{categoryTitle}</p> {!!itemCount && <span>{`(${itemCount})`}</span>}</h3>
            <div className='filterHeader'>
                <label className='selectCategoryBox'>
                    {<Select<ProductSortEnum>
                        value={sortBy}
                        options={SortByDropdown(!filter.hasDiscount ? [ProductSortEnum.DiscountLowToHigh, ProductSortEnum.DiscountHighToLow] : [])}
                        onChange={chosen => chosen && changeSortBy(chosen.value)}
                        hideIcon={true}
                        priceIconShow={true}
                        showSortIcon={true}
                    />}
                    {/* <img alt='slick Icon' className='slickIconSelect' src={slackIcon}/> */}
                </label>
                <div className='showFilterButton' onClick={openFilter}>
                    <SVGFilterIcon/>
                </div>
            </div>
        </div>
    );
};

export default SortBox;
