

import BrandController from "platform/api/brand";
import "./promo.scss"
import Settings from 'platform/services/settings';
import React, { useState, useEffect, useCallback } from 'react';

export function PromoCollectionCover({CollectionId,callTitle,itemCount}:any) {
  const [data,setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false);


  const fetchData = useCallback(async () => {
    setLoading(true);

    try {

      if(CollectionId) {
        const result = await BrandController.GetPromoCollectionCover(CollectionId);
        setData(result?.data);
        callTitle(Settings.translations.products)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(!CollectionId) { setData(null) }
  }, [CollectionId]);

  if(!data) return null
  return (
    <div className="promo_coverMain">
      <img className='promo_cover' src={data.detailCoverPhoto} alt={data.title}/>
      <div className='detail_promo'>
          <div className='detail'>
            <p className='name'>{data.title}</p>
            {/* <p className='count'>{itemCount}  {Settings.translations.products}</p> */}
          </div>
      </div>
      <p className='description'>{data.description}</p>
    </div>


  );
};