import * as React from 'react';

import LoaderContent from 'components/loader-content';
import Settings from 'platform/services/settings';
import HelperComponent from 'platform/classes/helper-component';
import { IVerifyRequestModel, ISendCodeRequestModel } from 'platform/api/auth/models/request';
import { validateForm } from './services/helper';
import AuthController from 'platform/api/auth';
import { ModalContentEnum } from '../../constants/enums';
import NumberInput from 'components/number-input';
import RecoveryCodeTimer from '../recoveryTime';


interface IProps {
  onTypeChange(type: ModalContentEnum,phoneNumber?:any): void;
  activeData: any;
};


enum PageType {
  Login,
  Bonus,
  Register
}

interface IState {
  submited: boolean;
  submitLoading: boolean;
  form: IVerifyRequestModel;
  startAgain:boolean
};

class RestoreVerify extends HelperComponent<IProps, IState> {

  public state: IState = {
    submited: false,
    submitLoading: false,
    form: {
      phoneNumber: '',
      code: 0,
      countryCode:''
    },
    startAgain:false
  };

  private get formValidation() {
    const { submited, form } = this.state;
    return validateForm?.call(form, submited);
  }

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = +e.currentTarget.value;
    this.safeSetState({ form })
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.safeSetState({ submited: true }, async () => {
      this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
        const { onTypeChange } = this.props;
        const { form } = this.state;
        const activeData = this.props.activeData as { form: ISendCodeRequestModel };
        form.phoneNumber = activeData.form.number;
        form.countryCode = activeData.form.countryCode;

        const result = await AuthController.Login(form);
        
        if (result.success) {
          Settings.authToken = result.data.accessToken;

          if(result?.data?.pageType === PageType.Login) {
            Settings.token = result.data.accessToken;
            window.location.reload();
          } else if(result?.data?.pageType === PageType.Bonus) {
            onTypeChange(ModalContentEnum.AttachBonusCard,{phoneNumber:form.phoneNumber,countryCode:form.countryCode});
          } else if(result?.data?.pageType === PageType.Register) {
            onTypeChange(ModalContentEnum.SignUp);
          } 

        } else this.safeSetState({ submitLoading: false });


        // if(activeData.fromSocial) {
        //   window.location.reload()
        // } else if(result?.data?.hasBonus && activeData.signUp) {
        //   onTypeChange(ModalContentEnum.AttachBonusCard,{phoneNumber:form.phoneNumber,countryCode:form.countryCode});
        // } else if(!result?.data?.hasBonus && activeData.signUp) {
        //   onTypeChange(ModalContentEnum.SignUp);
        // } else {
        //   onTypeChange(ModalContentEnum.NewPassword);
        // }
      });
    });
  }

  private resendCode = (e: React.SyntheticEvent) => {
    e.preventDefault();
    
    const  SendCode = async () =>  {
      const { form }:any = this?.props?.activeData
      
      const result = await AuthController.SendCode(form);
      if(result?.success) {
        this.setState({startAgain:!this.state.startAgain})
      }
    }

    SendCode()
  }

  private signIn = () => {
    const { onTypeChange } = this.props;
    onTypeChange(ModalContentEnum.SignIn);
  };

  public render() {
    const { form, submitLoading,startAgain } = this.state;
    const { form:propsForm } = this.props.activeData
      
    return <div className='RestoreVerify_popUp'>
      <h3 className="G-clr-main G-text-center">{Settings.translations.verificationCode}</h3>
      <p className="P-sign-in-register-text">{Settings.translations.sent_you_code} +{propsForm?.countryCode}{propsForm?.number} {Settings.translations.sent_you_PhoneNumber}</p>

      <form className="G-main-form">
        <div className="G-main-form-field sendCodePlaceTitle">
        <p className='placeUpHolder'>{Settings.translations.verificationCode}</p>
          <NumberInput
            type='number'
            int={true}
            name="code"
            value={form.code}
            placeholder={Settings.translations.code}
            onChange={this.changeField}
            className={`G-main-input ${this.formValidation.errors.code ? 'G-invalid-field' : ''}`}
          />
        </div>

        <RecoveryCodeTimer callBack={this.resendCode} startAgain={startAgain}/>

        <LoaderContent
          className="G-main-button"
          loading={submitLoading}
          onClick={this.submit}
          id={'verify_btn'}
        >{Settings.translations.next}</LoaderContent>
      </form>
      {/* <div className='sign_up_button'>
        <span className="P-sign-in-register-text">{Settings.translations.already_have_acount}</span>
        <button id={'sign_up_btn'} className=" P-sign-in-register G-main-button" onClick={this.signIn}>{Settings.translations.log_in}</button>
      </div> */}
    </div>;
  }
}

export default RestoreVerify;
