import * as React from 'react';

import NumberInput from '../number-input';
import HelperPureComponent from 'platform/classes/helper-pure-component';
import './style.scss';
import { SVGComponentRemoveIcon } from 'components/svg-components';
import Settings from 'platform/services/settings';

interface IState {
  count: string | number;
  plused: boolean;
};

class CountInput extends HelperPureComponent<any, IState> {

  public state: IState = {
    count: 0,
    plused: false, 
  }

  public componentDidMount() {
    const { value, defaultValue, min } = this.props;
    const intDefaultValue = Number(defaultValue || defaultValue === 0 ? defaultValue : value);
    this.safeSetState({ count: intDefaultValue || intDefaultValue === 0 ? intDefaultValue : min, plused: intDefaultValue > 0 });
  }

  private countInvalid = () => {
    const { min, step, withPlus } = this.props;
    const { count } = this.state;

    if (withPlus) return +count && +count < +min || (+count * 100) % (+step * 100);
    return +count < +min || (+count * 100) % (+step * 100);
  }

  private stepUp = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { count } = this.state;
    const { step, value, onChange, allowToStockQuantyty } = this.props;

    if(allowToStockQuantyty || allowToStockQuantyty === undefined ) {
      if(+count < 100 && allowToStockQuantyty) {
        const stepUped = value ? +value + step : +count + step;
        this.safeSetState({ count: stepUped }, () => onChange(+this.state.count, !this.countInvalid()));
      }
    }
  }

  private stepDown = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { count } = this.state;
    const { step, onChange, min, value } = this.props;
    if(+count > 1 || value > 1) {
      const stepDowned = value ? +value - step : +count - step;
      this.safeSetState({ count: +stepDowned >= +min ? stepDowned : min }, () => {
        const newCount = +stepDowned >= +min ? stepDowned : min
        onChange(newCount, !this.countInvalid())
      });
    }

  }

  private stepDownIsDeleteAllow = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const { count } = this.state;
    const { step, onChange, min, value } = this.props;
    if(+count > 1 || value > 1 ) {
      const stepDowned = value ? +value - step : +count - step;
      this.safeSetState({ count: +stepDowned >= +min ? stepDowned : min }, () => onChange(+this.state.count, !this.countInvalid()));
    }

  }

  private changeCount = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    if(+e.currentTarget.value < 100) {
      this.safeSetState({ count: !isNaN(+e.currentTarget.value) ? +e.currentTarget.value : 0 }, () => onChange(+this.state.count, !this.countInvalid()));
    }
  }

  private initCount = () => {
    const { count } = this.state;
    const { min, withPlus, onChange } = this.props;

    !count && withPlus && this.safeSetState({ count: +min }, () => onChange(+this.state.count, !this.countInvalid));
  }

  private outStock = async () => {
    const alertify = await import('alertifyjs');
    alertify.set('notifier', 'position',"bottom-right");

    alertify.error(`
      <div class="MuiAlert_standardError_child">
        ${Settings.translations.out_of_stock}
      </div>
    `);
  }
  
  public render() {
    const { withPlus, onlyPlusIfOne, value, allowDeleteItemBasket, onDeleteBasket, isaddedtobasket, allowToStockQuantyty } = this.props;
    const { plused } = this.state;
    const count = value || this.state.count;


    const alowAdd = !allowToStockQuantyty || allowToStockQuantyty === undefined
    return (
      <div className={`P-count-input ${!count && withPlus ? 'P-count-input-plus' : ''}`}
        onClick={this.initCount}
      >
        {!count && withPlus ? <>&#43;</> : 
        <>
          {(!onlyPlusIfOne || plused) && <>
            {
              allowDeleteItemBasket && count === 1 && isaddedtobasket ? 
              <p className='count_plus_minus minus remove_basketItem' onClick={onDeleteBasket}><SVGComponentRemoveIcon/></p> :
              <span className={`count_plus_minus minus ${onlyPlusIfOne && (count === 1) ? "disabledMinus" : ''}`} 
                onClick={count === 1 ? this.stepDownIsDeleteAllow : this.stepDown}
              >-</span>
            }
            <NumberInput
              disabledInput={true}
              value={count.toString()}
              className={this.countInvalid() ? 'P-invalid' : ''}
              onChange={this.changeCount}
            />
          </>}
          <span className={`count_plus_minus plus ${alowAdd ? 'disabledMinus' : ''}`} onClick={!alowAdd ?  this.stepUp : this.outStock}>+</span>
        </>}
      </div>
    );
  }
}
export default CountInput;