import * as React from 'react';
import * as DateTime from 'react-datetime';

import LoaderContent from 'components/loader-content';
import Settings from 'platform/services/settings';
import HelperComponent from 'platform/classes/helper-component';
import { RegisterForm, validateForm } from './services/helper';
import { ModalContentEnum } from '../../constants/enums';
import UserController from 'platform/api/user';
import Select from 'components/select';
import { GenderEnum } from 'platform/api/user/constants/enums/gender';
import { GenderDropdown } from 'platform/constants/dropdowns';
import { formatDate } from 'platform/services/helper';
import moment, { Moment } from 'moment';
import { IDropdownOption } from 'platform/constants/interfaces';
import slackIcon from "assets/images/icons/ic_arrow.svg"



interface IProps {
  onTypeChange(type: ModalContentEnum): void;
  bonusCardData:any
};

interface IState {
  submited: boolean;
  submitLoading: boolean;
  form: RegisterForm;
  termsConditionKey:boolean;
  openDate:boolean
};

class SignUp extends HelperComponent<IProps, IState> {

  public state: IState = {
    submited: false,
    submitLoading: false,
    openDate:false,
    form: {
      firstName: '',
      lastName: '',
    },
    termsConditionKey:false
  };

  public componentDidMount() {
    const { bonusCardData } = this.props
    const { form } = this.state;

    if(bonusCardData?.firstName) {
      form.firstName = bonusCardData.firstName
      form.lastName = bonusCardData.lastName
      form.gender = bonusCardData.gender
      form.dateOfBirth = bonusCardData.dateOfBirth
  
      this.safeSetState({ form })
    }

  }

  private get formValidation() {
    const { submited, form } = this.state;
    return validateForm?.call(form, submited);
  }

  private changeField = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { form } = this.state;
    form[e.currentTarget.name] = e.currentTarget.value;
    this.safeSetState({ form })
  }

  private changeGender = (chosen: IDropdownOption<GenderEnum>) => {
    const { form } = this.state;
    form.gender = chosen.value;
    this.safeSetState({ form });
  }

  private changeDateOfBirth = (chosen: Moment) => {
    const { form } = this.state;
  
    try {
      const dateOfBirth = chosen ? chosen.toDate() : null;
  
      if (dateOfBirth instanceof Date && !isNaN(dateOfBirth.getTime())) {
        form.dateOfBirth = dateOfBirth.toISOString();
      } else {
        console.error('Invalid dateOfBirth:', dateOfBirth);
        // Handle the case when dateOfBirth is invalid
      }
    } catch (error) {
      console.error('Error occurred while processing dateOfBirth:', error);
      // Handle the specific error related to chosen.toDate() here
    }
  
    this.handleOpenDate(false);
    this.safeSetState({ form });
  }

  private submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!!Settings.authToken) {Settings.token = Settings.authToken;}
    if(this.state.termsConditionKey) {
      this.safeSetState({ submited: true }, async () => {
        this.formValidation.valid && this.safeSetState({ submitLoading: true }, async () => {
          const { form } = this.state;
          if (Settings.referralCode) form.referralCode = Settings.referralCode;
          
          const result = await UserController.Register(form);
          if (result.data) {
            Settings.token = result.data.accessToken;
            window.location.reload();
          } else this.safeSetState({ submitLoading: false });
        });
      });
    }

  }

  // private isValidDate = (date: moment.Moment) => {
  //   const invalidDate = new Date();
  //   const minDate = new Date();
  //   const minDateFormat = minDate.setFullYear(1920);
  //   return !date.isSameOrAfter(invalidDate) && date.isSameOrAfter(minDateFormat);
  // }

  private isValidDate = (date: moment.Moment) => {
    const today = moment(); // Current date
    const minValidDate = today.clone().subtract(18, 'years'); // Minimum valid date for 18 years old
    const earliestValidDate = moment('1920-01-01'); // Earliest valid date (January 1, 1920)
  
    // Ensure the date is not in the future and is at least 18 years ago
    return date.isSameOrBefore(minValidDate) && date.isSameOrAfter(earliestValidDate);
  };

  private signIn = () => {
    const { onTypeChange } = this.props;
    onTypeChange(ModalContentEnum.SignIn);
  };

  private termsConditionButton = () => {
    if(this.state.termsConditionKey){this.state.termsConditionKey = false}
    else {this.state.termsConditionKey = true}
  };

  private handleOpenDate = (key:boolean) => {
    this.setState({ openDate: key });
  }



  public render() {
    const { form, submitLoading, openDate } = this.state;
    const {Enter_input,Confirm_text} = Settings.translations

    return <div className='signUp_PopUp'>
      <h3 className="G-clr-main G-text-center">{Settings.translations.sign_up}</h3>
      <span className="P-sign-in-register-text">{Settings.translations.sign_Up_desc_text}</span>
      <form className="G-main-form">
        <span className="P-sign-title_us">{Settings.translations.first_name}</span>
        <div className="G-main-form-field firstNameFieldSubTitle">
          <input
            autoComplete={'off'}
            name="firstName"
            value={form.firstName}
            placeholder={`${Enter_input} ${Settings.translations.first_name}`}
            onChange={this.changeField}
            className={`G-main-input ${this.formValidation.errors.firstName ? 'G-invalid-field' : ''}`}
          />
        </div>

        <span className="P-sign-title_us">{Settings.translations.surname}</span>
        <div className="G-main-form-field lastNameFieldSubTitle">
          <input
            autoComplete='off'
            name="lastName"
            value={form.lastName}
            placeholder={`${Enter_input} ${Settings.translations.last_name}`}
            onChange={this.changeField}
            className={`G-main-input ${this.formValidation.errors.lastName ? 'G-invalid-field' : ''}`}
          />
        </div>

        <span className="P-sign-title_us">{Settings.translations.gender}</span>
        <div className="G-main-form-field genderFieldSubTitle">
          <Select<GenderEnum>
            placeholder={`${Settings.translations.genderText}`}
            options={GenderDropdown()}
            value={form.gender}
            className={`G-main-select ${this.formValidation.errors.gender ? 'G-invalid-select' : ''}`}
            onChange={this.changeGender}
            hideIcon={true}
          />
          <img className='genderSlackIcon' src={slackIcon}/>
        </div>

        <span className="P-sign-title_us">{Settings.translations.date_of_birth}</span>
        <div className="G-main-form-field dateTimeFieldSubTitle" onClick={(e:any) => {
          const targetClassList = e.target.classList;
          if (targetClassList && targetClassList.contains('G-main-input')) {
            this.handleOpenDate(!this.state.openDate);
          } else {
            e.stopPropagation();
          }
        }}>
          <DateTime
            onChange={(value: string | Moment) => {
              if (typeof value === 'string') {
                const momentValue = moment(value, 'YYYY-MM-DD'); 
                this.changeDateOfBirth(momentValue);
              } else {
                this.changeDateOfBirth(value);
              }
            }}
            timeFormat={false}
            isValidDate={this.isValidDate}
            closeOnSelect={false}
            open={openDate}
            inputProps={{
              value: form.dateOfBirth ? formatDate(form.dateOfBirth, false) : '',
              // readOnly: true,
              className: `G-main-input ${this.formValidation.errors.dateOfBirth ? 'G-invalid-field' : ''}`,
              placeholder: `${Confirm_text} ${Settings.translations.date_of_birth}`,
            }}
          />
          <img className='genderSlackIcon' src={slackIcon}/>
        </div>

        <div className='termsCondition'>
          <label className="checkbox-label">
              <input type="checkbox" id="termConditionCheckbox" onChange={this.termsConditionButton}/>
              <span className="terms-label">{Settings.translations.termAndCondition}</span>
          </label>
        </div>

        <LoaderContent
          className="G-main-button"
          loading={submitLoading}
          onClick={this.submit}
          id={'sign_up_btn'}
        >
          {Settings.translations.confirm}
        </LoaderContent>
      </form>

      <div className='sign_up_button'>
        <span className="P-sign-in-register-text">{Settings.translations.already_have_acount}</span>
        <button id={'sign_up_btn'} className=" P-sign-in-register G-main-button" onClick={this.signIn}>{Settings.translations.log_in}</button>
      </div>
    </div>;
  }
}

export default SignUp;
