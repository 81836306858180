

export function ResizerImgSizeDinamic(imgUrl:string,size:number[]) {
    // const modifiedURL = `${imgUrl}/${size[0]}/${size[1]}`
    const modifiedURL = `${imgUrl}`

    return modifiedURL
}

export function ResizerImgSizeStatic(imgUrl:string) {
    // return `${imgUrl}/400/400`
    return `${imgUrl}`
}

export function ResizerImgPharmacyStatic(imgUrl:string) {
    return `${imgUrl}/350/350`
    // return `${imgUrl}`
}