

import BrandController from "platform/api/brand";
import "./brand.scss"
import Settings from 'platform/services/settings';
import React, { useState, useEffect, useCallback } from 'react';
import { CategoryPageTypeEnum } from "../..";

export function BrendCover({CategoryPageType,callTitle,itemCount}:any) {
  const [data,setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false);


  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const query = new URLSearchParams(window.location.search);
      const codeSap = query.get('codeSap');

      if (CategoryPageType === `${CategoryPageTypeEnum.brandPage}`) {
        const result = await BrandController.GetBrandDetail(codeSap);
        setData(result?.data);
        !!result?.data?.coverPath ? callTitle(Settings.translations.products) : callTitle(result?.data?.title)
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {fetchData()}, []);

  useEffect(() => {
    if(!CategoryPageType) { setData(null) }
  }, [CategoryPageType]);


  if(!data || !data.coverPath) return null
  return (
    <div className="brand_coverMain">
      <div className='Brand_cover' style={{ background: `url('${data.coverPath}') center/contain no-repeat` }}/>
      <div className='detail_Brand'>
          <div className='detail'>
            <p className='name'>{data.title}</p>
            {/* <p className='count'>{itemCount} {Settings.translations.products}</p> */}
          </div>
        </div>
      <p className='description'>{data.description}</p>
    </div>
  );
};