import * as React from 'react';

import HelperComponent from 'platform/classes/helper-component';
import { PharmacyType, AddressType } from 'platform/api/pharmacyBranch/models/response';
import './style.scss';
import imgUrl from "../../../../../assets/images/Natali-Pharm1.jpg"
import { SVGComponentCalendar, SVGComponentLocation, SVGComponentPhone } from 'components/svg-components';
import { ResizerImgPharmacyStatic, ResizerImgSizeStatic } from 'platform/hooks/useImageSize';

interface IProps {
  pharmacy: PharmacyType[];
  open:boolean,
  callBack: ({addressLat,addressLng,currentID}:AddressType) => void,
  currentID:number
}

interface IState {
}

class NewSearchResults extends HelperComponent<any, IState> {

  public state: IState = {
  };

  public render() {
    const { pharmacy, open, callBack, currentID } = this.props;
    
    return (
      <section className={`P-pharmacies-search-results ${open ? 'openMap' : ''}`}>
        {pharmacy.map(({id,name,addressText,addressLat,addressLng,contactPhoneNumber,workingTime,photoPath}) => {
          const photoForMap = photoPath ? ResizerImgSizeStatic(photoPath) : imgUrl

          return (
            <div key={id} className={`P-list ${currentID === id ? 'activeLocation' : ''}`} 
              onClick={() => callBack({addressLat,addressLng,currentID:id})}
            >
              <img alt='imgPharmacy' className='imgPharmacy' src={photoForMap} />
              <div className='informationPharmacy'>
                <p className='title'>{name}</p>
                {addressText && 
                <div className='desc_info address'>
                  <SVGComponentLocation/>
                  <p className='titleDescInfo'>{addressText}</p>
                </div>}
                {contactPhoneNumber && 
                <div className='desc_info phone'>
                  <SVGComponentPhone/>
                  <p className='titleDescInfo'>{contactPhoneNumber}</p>
                </div>}
                {workingTime && 
                <div className='desc_info workingTime'>
                  <SVGComponentCalendar/>
                  <p className='titleDescInfo'>{workingTime}</p>
                </div>}
              </div>
            </div>
          )
        })}
      </section>
    );
  }


};

export default NewSearchResults;
