import * as React from 'react';
import ROUTES from 'platform/constants/routes';
import { sendIsRestoreKey } from 'platform/hooks/useStorage';

import Connection from 'platform/services/connection';
import environment from 'platform/services/environment';
import { Link } from 'react-router-dom';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import CategoryController from 'platform/api/category';

const CategoryProductsFilters = ({ Data, body, onChange, onCloseMobilePopUp }: any) => {
  const [data, setData] = React.useState<any>();
  const [parentData, setParentData] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(true);

  React.useEffect(() => {
    (async () => {
      const query = new URLSearchParams(window.location.search);
      const categoryIds = query.get('categoryIds');
      
      const result = await CategoryController.GetListTitle(Number(categoryIds));
      setParentData(result?.data)
    })();
  }, [Data]);

  const Refresh = async (id: any) => {
    sendIsRestoreKey()
    onChange(body)

    if(window.innerWidth < 1200) { onCloseMobilePopUp() }
  }

  React.useEffect(() => { 
    setLoading(false)
    setData(Data)
  },[Data]);

  const handleClick = (e:any) => {
    if (!parentData?.parentId) {
      e.preventDefault(); //
      return;
    }

    Refresh(!parentData?.id);
  };


  console.log(Data,"Data")

  // if (!data?.categories?.length) return null;
  return <>
    <Link
      to={`${ROUTES.PRODUCTS.MAIN}?categoryIds=${parentData?.parentId}&sortBy=${ProductSortEnum.PriceHighToLow}`}
      onClick={handleClick}
      className={`P-row-title P-row-title-category ${!!parentData?.parentId ? 'isCursoirHover' : ''}`}
    >
      {!!parentData?.parentId && <i className="rotateSlack icon-Group-5513" />}
      {parentData?.name}
    </Link>
    

    {loading ? <ShimmerLoading MClass={'shimmer-category-filter'} colCount={8} childColCount={2} className={"P-row-title-shimmer-loading"} /> :
        !!data?.length && <div className={`P-row-content-category`}>
        {data?.map(({ id, name }: any) => {
          return (
            <Link
              key={id}
              to={`${ROUTES.PRODUCTS.MAIN}?categoryIds=${id}&sortBy=${ProductSortEnum.PriceHighToLow}`}
              onClick={e => Refresh(id)}
            >
              <p className='title_popup' title={name}>{name}</p>
            </Link>
          )
        })}
    </div>}
  </>
};

export default CategoryProductsFilters;



