import * as React from 'react';
import Select from 'components/select';
import Settings from 'platform/services/settings';
import { LanguageEnum } from 'platform/constants/enums';
import { IDropdownOption } from 'platform/constants/interfaces';
import './style.scss';
import Storage from 'platform/services/storage';
import ProductController from "../../../../platform/api/product";
import BlogController from "../../../../platform/api/blog";
import FaqController from "../../../../platform/api/faq";
import VacancyController from "../../../../platform/api/vacancy";



const changeLanguage = async (chosen: IDropdownOption<LanguageEnum>) => {
    const lang=window.location.pathname.slice(1,3)
    Settings.language = chosen.value;
    const href=window.location
    if (href.pathname.search('products/details') === 4){
        const prodId = href.pathname.split('/')
        const result = await ProductController.GetDetails(+prodId[4]);
        window.location.pathname = '/' + chosen.value + '/products/details/'+ prodId[4] + '/' + result.data.slug
    }
    else if(href.pathname.search('blogs') === 4 && href.pathname.length > 10){
        const prodId=href.pathname.split('/')
        const result = await BlogController.GetDetails(+prodId[3]);
        window.location.pathname='/'+chosen.value+'/blogs/'+prodId[3]+'/'+result.data.slug
    }
    else if(href.pathname.search('faq')===4 && href.pathname.length>10){
        const prodId=href.pathname.split('/')
        const result = await FaqController.GetDetails(prodId[3]);
        window.location.pathname='/'+chosen.value+'/faq/'+prodId[3]+'/'+result.data.slug
    }
    else if(href.pathname.search('vacancies')===4 && href.pathname.length>10){
        const prodId=href.pathname.split('/')
        const result = await VacancyController.GetDetails(prodId[3])
        window.location.pathname='/'+chosen.value+'/vacancies/'+prodId[3]+'/'+result.data.slug ;
    } else{
        window.location.pathname=window.location.pathname.replace(lang,chosen.value)
    }


};

const LanguageSwitcher = React.memo(({initialLoading}:{initialLoading:boolean}) => {
  const languagesDropdown = Storage.languages?.map((item:any) => ({ name: item.name, value: item.isoCode }));


  return (
    <Select<LanguageEnum>
        className="P-header-language-switcher"
        onChange={changeLanguage}
        options={languagesDropdown}
        value={Settings.language}
    />
  )
});

export default LanguageSwitcher;
