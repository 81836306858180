import * as React from 'react';
import './style.scss';
import Settings from 'platform/services/settings';
import { BasketEmptySTateSvg } from 'components/svg-components';


const EmptyStateProduct = React.memo(() => {

  return (
    <div className='ProductEmptyMain'>
      {/* <BasketEmptySTateSvg/> */}
      <span className='P-desc'>{Settings.translations.EmptyProduct}</span>
    </div>
  );
})

export default EmptyStateProduct;
