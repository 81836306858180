import * as React from 'react';
import ClickOutside from 'components/click-outside';
import Settings from 'platform/services/settings';
import { IProductSearchProductResponseModel, IProductSearcResponseModel } from 'platform/api/product/models/response';
import { Link } from 'react-router-dom';
import { formatPrice } from 'platform/services/helper';
import { PromotionTypeEnum } from 'platform/constants/enums';
import HelperComponent from 'platform/classes/helper-component';
import './style.scss';
import SearchHistory from 'platform/services/searchHistory';
import EmptyState from 'components/empty-state';
import { SVGComponentSearchSlack } from 'components/svg-components';
import { ResizerImgSizeDinamic } from 'platform/hooks/useImageSize';
import ROUTES from 'platform/constants/routes';
import GlobalStorageData from 'platform/services/search';
import { RevertStyleForIosMainContent, SetStyleForIosMainContent } from 'platform/hooks/setNotScrollledForIos';
import { ProductSortEnum } from 'platform/api/product/constants/enums';

interface IProps {
  searchText: string;
  data: IProductSearcResponseModel | null;
  historyShown: boolean;
  onClose(): any;
  onSubmit(): void;
}

interface IState {
  data: IProductSearcResponseModel | null;
}

class SearchPopup extends HelperComponent<any, IState> {


  public state: IState = {
    data: null,
  }

  public componentDidMount() {
    const { data } = this.props;
    // SetStyleForIosMainContent()
    this.safeSetState({ data });
  }

  public componentWillUnmount() {
    RevertStyleForIosMainContent()
  }

  private clickOnItem = (item: IProductSearchProductResponseModel) => {
    SearchHistory.add(item.id);
    const lang:any=Settings.language
    window.routerHistory.replace(`/${lang}/products/details/${item?.id}/${encodeURIComponent(item?.slugs[lang])}`);
    this.props.onClose();
  }

  private showAll = () => {
    const query = new URLSearchParams(window.location.search);
    const categoryIds = query.get('categoryIds');
    const categoryKey = categoryIds ? `categoryIds=${categoryIds}` : ''
    const newUrl = `${ROUTES.PRODUCTS.MAIN}?${categoryKey}&sortBy=${ProductSortEnum.PriceHighToLow}&page=1&text=${GlobalStorageData?.text}`;
    window.location.href = newUrl;
    this.props.onClose();
  }

  public render() {
    const { data } = this.state;
    const { onClose, historyShown } = this.props;
    const lang:any=Settings.language

    if(!data) return <EmptyState />;
    return (
      <ClickOutside onClickOutside={onClose}>
        <div className="P-header-search-result">
          {!!data?.length && <div className='seeAll'>
            <span className='seeAllText'>{Settings.translations.product}</span>
            <button className='seeAllButton' onClick={this.showAll}>{Settings.translations.show_all}</button>
          </div>}
          {data?.length ?
              data.map(item => {

                const isDiscount = item?.discountedPrice < item?.price;

                return (
                  <Link className="P-list-item" key={item.id} to={`/${lang}/products/details/${item?.id}/${encodeURIComponent(item?.slugs[lang])}`}
                    onClick={() => this.clickOnItem(item)}
                  >
                    <div className='img_and_title'>
                      <img className="searchItemImg" src={ResizerImgSizeDinamic(item.imagePath,[100,100]) }/>
                      <div className="title_content">
                        <p className='category'>{item.categoryName}</p>
                        <p className='title'>{item.name}</p>
                      </div>
                    </div>
  
                    <div className="P-right">
                      {isDiscount && <del className="discount">{formatPrice(item?.price)}</del>}
                      <p className="P-price">{formatPrice(item.discountedPrice)}</p>
                    </div>
  
                    <SVGComponentSearchSlack/>
                  </Link>
                )
              }) : <EmptyState />}
        </div>
      </ClickOutside>
    );
  }
}

export default SearchPopup;