import * as React from 'react';
import './style.scss';
import { ChatIcon, ToBottomSlack, UploadFileIcon } from 'components/svg-components';
import ChatController from 'platform/api/chat';
import ScrollFetch from 'components/scrollFetch';
import { useInView } from "react-intersection-observer";
import { toggleDissableScroll } from 'platform/services/helper';
import Socket from 'platform/services/socket';
import DispatcherChannels from 'platform/constants/dispatcher-channels';

enum chatEnum {
  stepCount = 10
}

interface Message {
  id: number;
  content: string;
  date: string; // Adjust the type based on your API response
}

interface StepRef {
  itemCount:number
}

const ChatComponent = React.memo(({isIncluded}:any) => {
  const [openChat, setOpenChat] = React.useState<boolean>(false);
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [messageText, setmessageText] = React.useState<string>('');
  const [productCount, setProductCount] = React.useState<number>(0);

  const [refresh, setRefresh] = React.useState<any>();
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const { ref, inView } = useInView();
  const stepRef = React.useRef<StepRef>({itemCount:0});
  const bodyRef = React.useRef<HTMLDivElement | null>(null);

  const toggleChat = () => {
    if (window.innerWidth < 700) {
      toggleDissableScroll();
    }
    setOpenChat((prev) => !prev)
  };

  const handleSendMessage = async (file?: any) => {
    if (messageText.trim() || file) {
      const form = new FormData();
      form.append("Text", messageText);
      if (file) {
        form.append('File', file);
      }
    
      const result = await ChatController.sendMessage(form);
    
      if (result.success) {
        setRefresh(!refresh);
      }
      setmessageText('');
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      handleSendMessage(file);
    }
  };

  function SortResponseAsDate(data: any) {
    const formatationDate = (dateString: string) => dateString.split("T")[0];
  
    const uniqueDates = new Set(); // Track unique dates
    const groupedMessages = Object.values(data?.reduce((acc: any, message: any) => {
        const date = formatationDate(message.createdDt);
      
        if (!uniqueDates.has(date)) {
          uniqueDates.add(date); // Add the date to the set
          acc[date] = { date, product: [] };
        }
  
        acc[date]?.product.push(message);
        acc[date]?.product.sort(
          (a: any, b: any) => new Date(a.createdDt).getTime() - new Date(b.createdDt).getTime()
        );
  
        return acc;
      }, {})
    );
  
    return groupedMessages;
  }

  const mergeMessagesByDate = (data: any[]) => {
    const dateMap = new Map();
  
    data.forEach(({ date, product }) => {
      if (!dateMap.has(date)) {
        dateMap.set(date, { date, product: product.slice() }); // Use slice() to copy the array
      } else {
        const existingEntry = dateMap.get(date);
        existingEntry.product = existingEntry.product.concat(product); // Concatenate without spread
      }
    });
  
    return Array.from(dateMap.values());
  };

  const handleFetchMessages = async (page: number = 1, clearCache: boolean) => {
    const payload = { count:chatEnum.stepCount, page };
    const result = await ChatController.fetchMessages(payload);

    stepRef.current.itemCount = result.data.itemCount

    if (result.success && !!result?.data?.list) {
      const sortedData: any = SortResponseAsDate(result?.data?.list).sort((a: Message, b: Message) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateA - dateB; // Ascending order
        }
      );

      if (clearCache) {
        setMessages(sortedData || []);
      } else {
        const newDat = mergeMessagesByDate(sortedData.concat(messages));
        setMessages(newDat)
      }

      setTimeout(() => {
        const chatContainer = bodyRef.current;
        if (chatContainer && page === 1) {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }  else if (chatContainer && page > 1) {
          chatContainer.scrollTop = chatContainer.clientHeight - 350;
        }
      }, 0);
    }
  };

  async function FetchData(openChatKey:boolean) {
    if(openChatKey) {
      await handleFetchMessages(1, true);
    } else {
      setMessages([])
    }
  }

  const formatTime = (dateString: string) => {
    if (!dateString) return ''; 
    const timePart = dateString.split("T")[1]; 
    return timePart.slice(0, 5); 
  };

  React.useEffect(() => {
    FetchData(openChat,)
  }, [openChat, refresh]);

  React.useEffect(() => {
    if (inView === true) {
      const newPage = Math.floor(productCount / 10) + 1

      handleFetchMessages(newPage, false);
    }
  }, [inView]);

  React.useEffect(() => {
    const productCunt = messages.reduce((aggr, val: any) => {
      aggr += val.product.length;
      return aggr;
    }, 0);

    setProductCount(productCunt)
  }, [messages]);

  React.useEffect(() => {
    const formatDate = (dateString: string) => dateString.split("T")[0];

  
    const handleSocketConnect = (event: any) => {
      const newMessage = event?.detail?.data;
      const formattedDate = formatDate(newMessage?.createdDt);
      setMessages((prevMessages: any) => {
        const updatedMessages = prevMessages.map((msg: any) => {
          if (msg.date === formattedDate) {
            const updatedMsg = { ...msg }; 
            updatedMsg.product = updatedMsg.product.concat(newMessage);
            return updatedMsg;
          }
          return msg; 
        });
        const existingDate = prevMessages.some((msg: any) => msg.date === formattedDate);
        if (!existingDate) {
          updatedMessages.push({ date: formattedDate, product: [newMessage] });
        }
        return updatedMessages;
      });

      setOpenChat((prev) => {
        if(!prev) {return true}
        return prev
      })
      setTimeout(() => {
        const chatContainer = bodyRef.current;
        if (chatContainer) { chatContainer.scrollTop = chatContainer.scrollHeight;}
      }, 100);

    };
  
    window.addEventListener(DispatcherChannels.SocketConnect, handleSocketConnect);
  
    return () => {
      window.removeEventListener(DispatcherChannels.SocketConnect, handleSocketConnect);
    };
  }, []);

  return (
    <div className={`chat_container ${isIncluded ? 'isInclude' : ''}`}>
      <span className="chatIcon" onClick={toggleChat}>
        <ChatIcon />
      </span>
      {openChat && (
        <div className="chat_popup">
          <div className="chat_header">
            <div className="chat_user">
              <ChatIcon />
              <span className="chat_name">Chat Bot</span>
            </div>
            <button className="chat_close" onClick={toggleChat}>
              <ToBottomSlack />
            </button>
          </div>
          <div ref={bodyRef} id="chat_body" className="chat_body">
            {(stepRef.current.itemCount > productCount && messages.length) ? <ScrollFetch ref={ref} /> : null}

            {messages?.map((group: any, idx: number) => {

              return (
                <div key={idx} className={`chat_group_day`}>
                  <p className='date'>{group.date}</p>
                  {group?.product.map((msg: any) => {
                      console.log(msg,"msg")
                    if (msg?.messageAttachments?.length) {
                      return (
                        msg?.messageAttachments.map((images:any) => {
                            return (
                            <div key={msg.id} className={`chat_message chat_image ${msg.isManager ? 'receiver' : 'sender'}`}>
                              <img className='image' src={images?.fileUrl} alt='message image' />
                              <span className='time'>{formatTime(msg?.createdDt)}</span>
                            </div>
                            )
                        })
                      );
                    } else {
                      return (
                        <div key={msg.id} className={`chat_message ${msg.isManager ? 'receiver' : 'sender'}`}>
                          <span className='message'>{msg?.text}</span>
                          <span className='time'>{formatTime(msg?.createdDt)}</span>
                        </div>
                      );
                    }
                  })}
                </div>
              );
            })}
          </div>
          <form className="chat_footer" onSubmit={(e) => {
            e.preventDefault()
            handleSendMessage()
          }}>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
            <div className='upload_file_button' onClick={() => fileInputRef.current?.click()}>
              <UploadFileIcon />
            </div>
            <input
              type="text"
              value={messageText}
              onChange={(e) => setmessageText(e.target.value)}
              placeholder="Type a message"
            />
            <button className='send_message'>Send</button>
          </form>
        </div>
      )}
    </div>
  );
});

export default ChatComponent;
