import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './style.scss';
import ROUTES from 'platform/constants/routes';
import Storage from 'platform/services/storage';
import { ProductSortEnum } from 'platform/api/product/constants/enums';
import ShimmerLoading from 'components/loader-content/shimmerLoading';
import CategoryController from 'platform/api/category';
import generic from 'platform/decorators/generic';
import { byRoute } from 'platform/decorators/routes';
import Settings from 'platform/services/settings';

interface IRouteParams {
  id: string;
}

type IProps = RouteComponentProps<IRouteParams>;

interface State {
  categories: any[];
  ParentCategory:any
}

@generic<IProps>(withRouter)
@byRoute(ROUTES.PRODUCTS.DETAILS)
@byRoute([ROUTES.CATEGORY.DETAILS])
class CategoryMobileChild extends Component<IProps, State> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      categories: [],
      ParentCategory:undefined
    };
  }

  public componentDidMount() {
    const { id } = this.props.match.params; 

    if (id) {this.fetchDatata(id)}
  }

  private fetchDatata = async (id:string) => {
    const { category:ParentCategory }:any = this?.props?.location?.state;
    
    try {
      const response = await CategoryController.GetChildCategoryList(id); 
      if (response?.data?.length) {
        this.setState({ categories: response.data, ParentCategory }); 
      }
    } catch (error) {
      console.error('Error fetching child categories:', error);
    }
  };
  
  private RedirectUrl = (category: { id: string }) => {
    const url = `${ROUTES.PRODUCTS.MAIN}?categoryIds=${category?.id}&sortBy=${ProductSortEnum.PriceHighToLow}&catTitleID=${category?.id}`;
    this.props.history.push(url);
  };


  private clickItem = async (e: React.SyntheticEvent, category: any) => {
    e.preventDefault();
    const {ParentCategory} = this.state
    if((category.id === ParentCategory.id) || !category.hasChild) {
      this.RedirectUrl(category)
    } else {
      try {
        const response = await CategoryController.GetChildCategoryList(category.id);
        if (response?.data?.length) {
          const color = this.getRandomColor();
          category.color = color
          this.setState({ categories: response.data,ParentCategory: category }); 
          console.log('Fetched child categories:', response.data);
        }
      } catch (error) {
        console.error('Error fetching child categories:', error);
      }
    } 
  };

  private getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, 0.2)`;
  };

  public render() {
    const { categories,ParentCategory } = this.state;

    if (!categories.length) {
      return (
        <ShimmerLoading
          MClass="allCategoryShimmer"
          colCount={10}
          childColCount={1}
          className="productShimmer"
        />
      );
    }

    return (
      <div className="categoryPage">
        <div
          key={ParentCategory.id}
          style={{ background: ParentCategory.color }}
          className="linkToCategory"
          onClick={(e) => this.clickItem(e, ParentCategory)}
        >
          {ParentCategory.name}
        </div>

        {categories.map((category) => {
          return (
            <div
              key={category.id}
              className="linkToCategoryChild"
              onClick={(e) => this.clickItem(e, category)}
            >
              {category.name}
              {category.hasChild && <i className="icon-Group-5513" />}
            </div>
          );
        })}
      </div>
    );
  }
}

export default CategoryMobileChild;
